import Image from "next/image";
import Link from "next/link";
import { FaDiscord, FaInstagram } from "react-icons/fa";

const navigation = {
  "AI Workflows": [
    { name: "Fake texts videos", href: "https://crayo.ai/create/text-video?step=1" },
    { name: "Reddit video", href: "https://crayo.ai/create/story-video?step=1" },
    { name: "ChatGPT video", href: "https://crayo.ai/create/chatgpt-video?step=1" },
    { name: "Split-screen video", href: "https://crayo.ai/create/split-video?step=1" },
    { name: "Blur video", href: "https://crayo.ai/create/blur-video?step=1" },
  ],
  "Crayo Tools": [
    { name: "AI ideas", href: "https://crayo.ai/tools/ideas" },
    { name: "AI images", href: "https://crayo.ai/tools/images" },
    { name: "AI voiceovers", href: "https://crayo.ai/tools/voiceovers?step=0" },
    { name: "YouTube downloader", href: "https://crayo.ai/tools/youtube-downloader" },
    { name: "TikTok downloader", href: "https://crayo.ai/tools/tiktok-downloader" },
  ],
  product: [
    { name: "Pricing", href: "https://crayo.ai/pricing" },
    { name: "Enterprise", href: "mailto:support@crayo.ai" },
  ],
  legal: [
    { name: "Refund policy", href: "https://crayo.ai/refund-policy" },
    { name: "Terms of service", href: "https://crayo.ai/tos" },
    { name: "Privacy policy", href: "https://crayo.ai/privacy-policy" },
    { name: "Affiliate TOS", href: "https://crayo.ai/affiliate-tos" },
  ],
};

const socials = [
  {
    name: "Discord",
    href: "https://crayo.ai/discord",
    icon: <FaDiscord className="size-6 text-white" />,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/crayoai",
    icon: <FaInstagram className="size-6 text-white" />,
  },
  {
    name: "X",
    href: "https://x.com/crayoai",
    icon: (
      <Image
        src="https://cdn-crayo.com/lp/public/footer-x.png"
        alt="X"
        width={0}
        height={0}
        className="size-5"
        style={{ filter: "invert(1)" }}
        draggable={false}
      />
    ),
  },
];

const SocialsBanner = () => (
  <div className="mx-auto w-full max-w-screen-2xl rounded-[20px] border border-[#484848] bg-[#262626] @container">
    <div className="mx-auto flex flex-col items-center justify-between space-y-6 px-6 py-9 @md:flex-row @md:space-y-0 lg:px-10">
      <div className="flex flex-col">
        <p className="max-w-2xl text-lg font-semibold leading-none tracking-[0.012em] text-[#F5F2EB] @lg:text-[27px]">
          Follow us across the web
        </p>
        <p className="mt-2 text-pretty text-sm leading-none tracking-[0.012em] text-[#F5F2EB] @lg:text-[16px]">
          Join our fast-growing community!
        </p>
      </div>
      <div className="flex items-center space-x-2.5">
        {socials.map((social) => (
          <button key={social.name} className="transition-all duration-200 hover:opacity-70">
            <Link
              prefetch={social.href === "https://crayo.ai/discord" ? false : true}
              href={social.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex size-11 items-center justify-center rounded-full bg-[#373737] p-2.5">
                {social.icon}
              </div>
            </Link>
          </button>
        ))}
      </div>
    </div>
  </div>
);

const Footer = () => {
  return (
    <div className="@container">
      <div className="flex w-full flex-col items-center space-y-8 rounded-t-[44px] border-t border-[#CDD6E3] bg-[#EFF6FF] px-6 py-12 @2xl:px-14 @2xl:py-20">
        <SocialsBanner />
        <footer className="mx-auto w-full max-w-screen-2xl rounded-2xl border border-[#E0E5EA] bg-white px-6 pb-14 shadow-sm lg:px-8">
          <div className="grid grid-cols-2 gap-8 py-12 pl-1 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-lg font-bold text-black">AI Workflows</h3>
                <ul role="list" className="mt-1.5 space-y-1.5">
                  {navigation["AI Workflows"].map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-[13px] text-gray-700 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-lg font-bold text-black">Crayo Tools</h3>
                <ul role="list" className="mt-1.5 space-y-1.5">
                  {navigation["Crayo Tools"].map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-[13px] text-gray-700 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-lg font-bold text-black">Product</h3>
                <ul role="list" className="mt-1.5 space-y-1.5">
                  {navigation.product.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-[13px] text-gray-700 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-lg font-bold text-black">Legal</h3>
                <ul role="list" className="mt-1.5 space-y-1.5">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-[13px] text-gray-700 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between border-t border-gray-900/10 pt-8">
            <Link
              href="/"
              className="w-1/4"
              onClick={(e) => {
                if (window.location.pathname === "/") {
                  e.preventDefault();
                  document.getElementById("top")?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <Image
                src="https://cdn-crayo.com/lp/public/crayoBlue.png"
                alt="CRAYO Logo"
                width={126}
                height={28.5}
                draggable={false}
              />
            </Link>
            <p className="text-xs text-black/70 @lg:text-base">&copy; 2025 Crayo, Inc.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
